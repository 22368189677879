<template>
  <div class="chart-container" :style="{ height: height }">
    <download-chart
      feature="HVAC"
      noDownload
      @click="download"
    ></download-chart>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
export default {
  props: {
    yTitle: {
      type: String,
      default: ''
    },
    labels: {
      type: Array
    },
    datasetLabel: {
      type: String
    },
    data: {
      type: Array
    },
    borderColor: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: Array
    },
    maxBarThickness: {
      type: Number,
      default: 90
    },
    borderWidth: {
      type: Number,
      default: 1
    },
    maintainAspectRatio: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '350px'
    }
  },
  data: () => ({
    context: null,
    chartObj: null
  }),
  computed: {
    chartData() {
      return {
        type: 'bar',
        options: {
          onClick: this.handleClick,
          responsive: true,
          maintainAspectRatio: this.maintainAspectRatio,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.yTitle,
                font: {
                  size: '20px'
                }
              }
            }
          }
        },
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.datasetLabel,
              data: this.data,
              backgroundColor: this.backgroundColor,
              //       borderColor: this.borderColor,
              maxBarThickness: this.maxBarThickness,
              borderWidth: this.borderWidth
            }
          ]
        }
      }
    }
  },
  components: {
    DownloadChart: () => import('@/components/Core/DownloadChart.vue')
  },
  mounted() {
    this.setContext()
    this.initChart()
  },
  methods: {
    setContext() {
      this.context = this.$refs.chart.getContext('2d')
    },
    initChart() {
      this.chartObj = new Chart(this.context, this.chartData)
    },
    handleClick(_e, item) {
      this.$emit('clickBar', { _e: _e, item: item })
    },
    updateChart() {
      this.chartObj.destroy()
      this.setContext()
      this.initChart()
    },
    download(filename) {
      this.downloadBase64Image(
        this.$refs.chart.toDataURL('image/png'),
        filename
      )
    }
  },
  watch: {
    data: {
      handler: 'updateChart'
    }
  }
}
</script>

<style scoped>
/* .chart-container {
  height: 350px;
} */
</style>
